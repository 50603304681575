@media (--mobile) {
  .countdown-hero__form-max-width {
    padding-right: 0;
    padding-left: 0;
  }
}

.countdown-hero__form {
  --border-radius: 999px;
  --js-scroll-offset-top: var(--header-height);

  position: relative;

  width: 100%;
  max-width: 850px;
  margin-top: calc(-1 * var(--form-top-offset));
  margin-right: auto;
  margin-left: auto;
  padding: 40px;

  border-radius: var(--border-radius);
  background-color: var(--black);
}
@media (--mobile) {
  .countdown-hero__form {
    --border-radius: 46px;

    padding-top: var(--page-horizontal-padding);
    padding-right: var(--page-right-padding);
    padding-bottom: 0;
    padding-left: var(--page-left-padding);
  }
}

.countdown-hero__form::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      180deg,
      rgba(var(--white-rgb), 0.3),
      rgba(var(--white-rgb), 0.05) calc(var(--form-top-offset) - 1px),
      rgba(var(--white-rgb), 0) var(--form-top-offset)
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}
@media (--mobile) {
  .countdown-hero__form::before {
    background: linear-gradient(
        180deg,
        rgba(var(--white-rgb), 0.2),
        rgba(var(--white-rgb), 0) calc(var(--form-top-offset) - 1px),
        rgba(var(--white-rgb), 0) var(--form-top-offset)
      )
      border-box;
  }
}
