.experts:first-child,
* + .experts {
  margin-top: var(--section-gap);
}

.experts {
  position: relative;

  overflow: hidden;
}

.experts::before,
.experts::after {
  --direction: to right;
  position: absolute;
  top: 0;
  z-index: 1;

  content: '';
  display: block;
  width: calc((100vw - var(--page-max-width)) / 2);
  height: 100%;

  background: linear-gradient(
    var(--angle),
    rgba(5, 2, 2, 0) 0%,
    #050202 88.75%
  );
  pointer-events: none;
}

.experts::before {
  --angle: -90deg;
  left: 0;
}
.experts::after {
  --angle: 90deg;
  right: 0;
}

.experts__button {
  position: absolute;
  top: 50%;
  z-index: 2;

  border: none;
  background: none;
  transform: translateY(-50%);
  cursor: pointer;
}
@media (--mobile) {
  .experts__button {
    display: none;
  }
}

.experts__button--prev {
  left: 10px;
}

.experts__button--next {
  right: 10px;
}

.experts__wrap {
  display: flex;
  width: 100%;
}
.experts__wrap.keen-slider:not([data-keen-slider-disabled]) {
  overflow: visible;
}

.experts__item {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  max-width: 450px !important;
  min-width: 450px !important;
  height: auto;

  border-radius: 8px;
}

@media (--tablet) {
  .experts__item {
    max-width: 250px !important;
    min-width: 250px !important;
  }
}

@media (--mobile) {
  .experts__item {
    max-width: 100%;
    min-width: 100%;
  }
}

.experts__item__image {
  flex-shrink: 0;
  overflow: hidden;
  width: 100%;
  height: 200px;
  object-fit: cover;
}
@media (--tablet) {
  .experts__item__image {
    height: 110px;
  }
}

.experts__item__image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.experts__item__content {
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 24px;

  background-color: var(--white);
}
@media (--mobile) {
  .experts__item__content {
    padding: 16px;
  }
}
.experts__item__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: -0.02em;
  color: var(--black);
}
@media (--tablet) {
  .experts__item__title {
    font-size: 20px;
  }
}

@media (--mobile) {
  .experts__item__title {
    font-size: 18px;
  }
}

.experts__item__description {
  position: relative;

  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  color: rgba(var(--black-rgb), 0.6);
}
* + .experts__item__description {
  margin-top: 16px;
}
@media (--tablet) {
  .experts__item__description {
    font-size: 16px;
  }
  * + .experts__item__description {
    margin-top: 8px;
  }
}

.experts__item__description::after {
  position: absolute;
  bottom: 0;
  left: 0;

  content: '';
  width: 100%;
  height: 32px;

  background-image: linear-gradient(
    to top,
    rgba(var(--white-rgb), 1),
    rgba(var(--white-rgb), 0)
  );
  opacity: 1;
  transition: 200ms opacity;
}
.experts__item__description--open::after {
  opacity: 0;
}

.experts__item__cta img {
  --size: 13px;

  position: relative;
  top: 4px;

  width: var(--size);
  height: var(--size);
  transition: 200ms transform;
}
.experts__item__cta--open img {
  transform: rotate(180deg);
}

.button__text {
  position: static;
}
.button__text::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';
}
