.countdown-form__timer {
  --gap: 16px;

  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
}
* + .countdown-form__timer {
  margin-top: 24px;
}
@media (--mobile) {
  .countdown-form__timer {
    --gap: 8px;
  }
  * + .countdown-form__timer {
    margin-top: 16px;
  }
}

.countdown-form__timer__item {
  position: relative;

  min-width: 76px;

  font-size: 18px;
  color: rgba(var(--white-rgb), 0.7);
  text-align: center;
}
@media (--mobile) {
  .countdown-form__timer__item {
    min-width: 64px;

    font-size: 15px;
  }
}

* + .countdown-form__timer__item {
  margin-left: calc(var(--gap) * 2);
}
* + .countdown-form__timer__item::before {
  position: absolute;
  top: 50%;
  left: calc(-1 * var(--gap));

  content: '';
  width: 1px;
  height: 56px;

  background-image: linear-gradient(
    to bottom,
    rgba(var(--white-rgb), 0),
    rgba(var(--white-rgb), 0.5) 50%,
    rgba(var(--white-rgb), 0)
  );
  transform: translateY(-50%);
}

.countdown-form__timer__item b {
  font-size: 36px;
  font-style: normal;
  font-weight: 800;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--white);
  text-align: center;
}
@media (--mobile) {
  .countdown-form__timer__item b {
    font-size: 28px;
  }
}
