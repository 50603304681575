.landing-footer {
  position: relative;

  padding-top: 24px;
  padding-bottom: 24px;

  background-color: var(--black);
}

.landing-footer::before {
  position: absolute;
  top: 0;
  left: 50%;

  content: '';
  width: 50%;
  height: 1px;

  background-image: linear-gradient(
    to right,
    rgba(var(--white-rgb), 0),
    rgba(var(--white-rgb), 0.3),
    rgba(var(--white-rgb), 0)
  );
  transform: translateX(-50%);
}

.landing-footer__legal p {
  font-size: 14px;

  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: rgba(var(--white-rgb), 0.7);
  text-align: center;
}

.landing-footer__legal p a {
  color: rgba(var(--white-rgb), 0.7);
  text-decoration: none;
  transition: 120ms color;
}
.landing-footer__legal p a:hover {
  color: var(--white);
  text-decoration: underline;
}
