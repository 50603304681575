.countdown-footer {
  position: relative;

  padding-bottom: 50px;

  background: radial-gradient(
      71% 76% at 50% 121%,
      rgba(227, 104, 100, 12%) 0%,
      rgba(227, 104, 100, 0%) 100%
    ),
    var(--black);
}

* + .countdown-footer {
  margin-top: 100px;
}
@media (--mobile) {
  * + .countdown-footer {
    margin-top: 50px;
  }
}

.countdown-footer__title {
  max-width: 40ch;
  margin-right: auto;
  margin-left: auto;

  font-size: 32px;

  font-weight: 600;
  line-height: 130%;
  letter-spacing: 0.01em;
  color: var(--white);
  text-align: center;
}

@media (--tablet) {
  .countdown-footer__title {
    font-size: 24px;
  }
}
@media (--mobile) {
  .countdown-footer__title {
    font-size: 20px;
  }
}

.countdown-form__subtitle {
  font-size: 20px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: rgba(var(--white-rgb), 0.7);
  text-align: center;
}
@media (--mobile) {
  .countdown-form__subtitle {
    font-size: 16px;
  }
}

* + .countdown-footer__form {
  margin-top: 32px;
}
