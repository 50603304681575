/* .contact-form__form {
  margin-top: 62px;
}

@media (--mobile) {
  .contact-form__form {
    margin-top: 49px;
  }
} */

.contact-form__form__title {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

.contact-form * + form {
  margin-top: 46px;
}

.contact-form__form__title + form {
  margin-top: 22px;
}

.contact-form__form--state-success form {
  display: none;
}

.contact-form__form__split {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 8px;
}

.contact-form__form__input-container,
.contact-form__form__input-container-textarea {
  --border-radius: 8px;

  position: relative;

  display: flex;
  width: 100%;
}

.contact-form__form__input-container::before,
.contact-form__form__input-container-textarea::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  border: 1px solid transparent;
  border-radius: var(--border-radius);
  background: linear-gradient(
      -45deg,
      rgb(255 255 255 / 35%),
      rgb(255 255 255 / 13%)
    )
    border-box;

  pointer-events: none;
  -webkit-mask:
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

@media (--tablet) {
  .contact-form__form__split {
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.contact-form__form__split + .contact-form__form__split {
  margin-top: 8px;
}

.contact-form__form__split--flex {
  display: flex;
}

.contact-form__form__input,
.contact-form__form__textarea {
  width: 100%;
  padding: 16px;

  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 300;
  line-height: 100%;
  letter-spacing: 0.04em;
  color: var(--white);

  border: 1px solid rgb(var(--white-rgb) 0);
  border-radius: var(--border-radius);
  background: rgba(255, 255, 255, 0.1);

  transition: border-color 200ms;
}

.contact-form__form__input:focus,
.contact-form__form__textarea:focus {
  outline: none;
  border-color: rgb(var(--white-rgb) 1);
}

@media (--mobile) {
  .contact-form__form__input,
  .contact-form__form__textarea {
    font-size: 16px;
  }
}

* + .contact-form__form__input,
* + .contact-form__form__textarea {
  display: flex;
  align-items: center;
  margin-top: 6px;
}

form > * + .contact-form__form__input-container,
* + .contact-form__form__input-container-textarea {
  margin-top: 8px;
}

/* @media (--mobile) {
  form > * + .contact-form__form__input-container,
  * + .contact-form__form__input-container-textarea {
    margin-top: 12px;
  }
} */

.contact-form__form__textarea {
  min-height: 112px;
  resize: vertical;
}

.contact-form__form__error {
  min-height: 30px;
}

.contact-form__form__error p {
  font-family: var(--font-family);
  font-size: 14px;

  font-weight: 300;
  line-height: 125%;
  letter-spacing: 0.04em;
  color: rgb(255 255 255 / 50%);
}

* + .contact-form__form__error {
  margin-top: 11px;
}

/* .contact-form__form__cta {
  display: flex;
  justify-content: flex-end;
}

@media (--mobile) {
  .contact-form__form__cta {
    justify-content: flex-start;
  }
} */

* + .contact-form__form--state-error .contact-form__form__cta {
  margin-top: 11px;
}

* + .contact-form__form__cta {
  margin-top: 16px;
}

@media (--mobile) {
  * + .contact-form__form__cta {
    margin-top: 21px;
  }
}
.contact-form__form__success {
  display: flex;
  align-items: center;

  gap: 24px;
  margin-top: 22px;
  padding: 16px;

  border-radius: 4px;

  background: rgba(255, 255, 255, 0.1);
}
@media (--mobile) {
  .contact-form__form__success {
    flex-direction: column;
    gap: 12px;
  }
}

.contact-form__form__success__message {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  color: var(--white);
}

@media (--mobile) {
  .contact-form__form__success__message {
    flex-direction: column;

    gap: 8px;
  }
}
