.comment-cards__item {
  padding: 24px;

  border-radius: 16px;
  background: #f8f8f8;
}
@media (--tablet) {
  .comment-cards__item {
    padding: 16px;
  }
}

.comment-cards__item__info {
  display: flex;
  align-items: center;
  justify-content: space-between;

  color: var(--color);
}

.comment-cards__item__profile {
  display: flex;
  align-items: center;
  gap: 12px;
}

.comment-cards__item__picture {
  --size: 52px;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  font-size: 19px;
  font-weight: 700;
  line-height: 1;

  border: white 6px solid;
  border-radius: 50%;
  box-shadow: 0 4.8px 14.4px 0 rgba(0, 0, 0, 0.05);

  background-color: #fddce5;
  user-select: none;
  object-fit: cover;
}
div.comment-cards__item__picture {
  padding-bottom: 4px;
}
.comment-cards__item:nth-child(2) .comment-cards__item__picture {
  background-color: #fffbd6;
}
.comment-cards__item:nth-child(3) .comment-cards__item__picture {
  background-color: #d1f9ea;
}
@media (--tablet) {
  .comment-cards__item__picture {
    --size: 44px;

    font-size: 17px;

    border-width: 4px;
  }
}

.comment-cards__item__info__content {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.comment-cards__item__info__content__name {
  font-size: 15px;
  font-weight: 700;
}

.comment-cards__item__info__content__info {
  font-size: 15px;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.6);
}

.comment-cards__item__rating__wrapper {
  display: flex;
  align-items: center;
  gap: 4px;
}
.comment-cards__item__rating {
  font-size: 17px;
  font-weight: 600;
}

.comment-cards__item__text {
  margin-top: 14px;

  font-size: 17px;
  font-weight: 400;
  line-height: 150%;
}
@media (--tablet) {
  .comment-cards__item__text {
    font-size: 16px;
  }
}
@media (--mobile) {
  .comment-cards__item__text {
    margin-top: 12px;

    font-size: 15px;
  }
}
