.form,
:--layout-dark .form,
:--group-dark .form {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .form,
:--group-light .form {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.form:first-child,
* + .form {
  margin-top: var(--section-gap);
}

.form {
  color: var(--color);
}

.form__max-width {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 48px;
}
@media (--tablet) {
  .form__max-width {
    gap: 24px;
  }
}
@media (--mobile) {
  .form__max-width {
    grid-template-columns: 1fr;
    gap: 16px;
  }
}

.form__title {
  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
}
@media (--tablet) {
  .form__title {
    font-size: 32px;
  }
}
@media (--mobile) {
  .form__title {
    font-size: 24px;
  }
}

.form__content {
  font-size: 16px;
  font-weight: 400;
  line-height: 1.6;
  letter-spacing: 0.01em;
  color: rgba(var(--black-rgb), 0.7);
}
* + .form__content {
  margin-top: 24px;
}

.form__content * + p,
.form__content * + ul,
.form__content * + ol {
  margin-top: 1em;
}

.form__content ul,
.form__content ol {
  padding-left: 16px;
}

.form__content ul * + li,
.form__content ol * + li {
  margin-top: 0.2em;
}

.form__form-title {
  font-size: 24px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.01em;
}
@media (--tablet) {
  .form__form-title {
    font-size: 20px;
  }
}
@media (--mobile) {
  .form__form-title {
    font-size: 18px;
  }
}

.form__error {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  color: var(--primary-500);
}
* + .form__error {
  margin-top: 12px;
}

.form__notice {
  font-size: 12px;
  font-weight: 300;
  line-height: 1.4;
  letter-spacing: 0.01em;
  color: rgba(var(--black-rgb), 0.7);
}
* + .form__notice {
  margin-top: 16px;
}

.form__notice * + p {
  margin-top: 1em;
}
