.expert:first-child,
* + .expert {
  margin-top: var(--section-gap);
}

.expert__wrap {
  --padding: 40px;

  display: grid;
  overflow: hidden;
  grid-template-columns: 35% 1fr;
  gap: var(--padding);

  border-radius: 8px;
  background-color: var(--white);
}
@media (--tablet) {
  .expert__wrap {
    --padding: 24px;
  }
}
@media (--mobile) {
  .expert__wrap {
    grid-template-columns: 1fr;
  }
}

.expert__left img {
  width: 100%;
  height: 100%;

  background-color: var(--light);
  object-fit: cover;
}

.expert__right {
  --gap: 24px;

  padding: var(--padding) var(--padding) var(--padding) 0;

  color: var(--dark);
}
@media (--mobile) {
  .expert__right {
    --gap: 16px;

    padding: 0 var(--padding) var(--padding) var(--padding);
  }
}

.expert__title {
  font-size: 24px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.01em;
}
@media (--mobile) {
  .expert__title {
    font-size: 18px;
  }
}

.expert__sub-title {
  font-size: 18px;
  font-weight: 400;
  line-height: 150%;
  color: rgba(var(--black-rgb), 0.6);
}
* + .expert__sub-title {
  margin-top: var(--gap);
}
@media (--mobile) {
  .expert__sub-title {
    font-size: 16px;
  }
}

.expert__right hr {
  height: 1px;

  border: 0;
  background-image: linear-gradient(
    to right,
    rgba(var(--black-rgb), 0),
    rgba(var(--black-rgb), 0.2),
    rgba(var(--black-rgb), 0)
  );
}
.expert__right * + hr {
  margin-top: var(--gap);
}

.expert__content {
  font-size: 14px;
  font-weight: 400;
  line-height: 160%; /* 22.4px */
  letter-spacing: 0.02em;
  color: rgba(var(--black-rgb), 0.8);
}
* + .expert__content {
  margin-top: var(--gap);
}

.expert__content * + p,
.expert__content * + ul {
  margin-top: 1em;
}

.expert__content ul li {
  padding-left: 32px;
}
.expert__content ul * + li {
  margin-top: 0.5em;
}

.expert__content a {
  color: var(--primary-500);
  text-decoration: none;
}
.expert__content a:hover {
  text-decoration: underline;
}
