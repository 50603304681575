* + .founder {
  margin-top: var(--section-gap);
}

@media (--mobile) {
  * + .founder {
    margin-top: 60px;
  }
}

.founder {
  --vertical-padding: 100px;
  --mobile-bottom-offset: 80px;
  --mobile-bottom-padding: 23px;
}

.founder--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}

.founder .max-width {
  position: relative;

  display: grid;
  grid-template-columns: 0.9fr 1fr;
  column-gap: 85px;
  row-gap: 20px;
}

@media (--tablet) {
  .founder .max-width {
    grid-template-columns: 1.2fr 0.8fr;
    column-gap: 40px;
  }
}

@media (--mobile) {
  .founder .max-width {
    display: block;
  }

  .founder {
    padding-bottom: var(--mobile-bottom-offset);
  }
}

.founder__top__title {
  font-family: var(--font-family-title);
  font-size: 50px;
  font-weight: 700;
  line-height: 110%;
  letter-spacing: 0.03em;
  color: var(--white);
}

.founder__top__title strong {
  color: #f0fa1d;
}

* + .founder__top__content {
  margin-top: 32px;
}

.founder__top {
  grid-row: 1;
  grid-column: 1;
}

.founder__top__content,
.founder__content-blok {
  font-size: 20px;
  font-weight: 400;
  line-height: 150%;
  letter-spacing: 0.03em;
  color: var(--white);
}

.founder__content-blok p:last-of-type::after {
  content: '';
  display: inline-block;
  width: var(--icon-width);
}

.founder__top__content b,
.founder__content-blok b {
  font-weight: 400;
  color: #f0fa1d;
}

.founder__image {
  grid-row: 1 / 3;
  grid-column: 2;
  width: 100%;
}

.founder__image img {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.founder__content-blok {
  --icon-width: 34px;
  --icon-height: 23px;

  position: relative;

  grid-row: 2;

  grid-column: 1;

  padding-top: var(--icon-height);
}

.founder__content-blok p:first-of-type {
  text-indent: var(--icon-width);
}

.founder__content-blok__quotes-first,
.founder__content-blok__quotes-last {
  position: absolute;

  width: var(--icon-width);
  height: var(--icon-height);
}

.founder__content-blok__quotes-first {
  position: absolute;
  top: 0;
  left: 0;

  width: var(--icon-width);
  height: var(--icon-height);
}

.founder__content-blok__quotes-last {
  right: 0;
  bottom: -8px;
  transform: scaleX(-1);
}

@media (--tablet) {
  .founder__top__title {
    font-size: 42px;
  }
}

@media (--mobile) {
  .founder__top__title {
    font-size: 33px;
  }

  .founder__top__content,
  .founder__content-blok {
    font-size: 16px;
  }

  .founder__content {
    position: absolute;
    right: var(--mobile-bottom-padding);
    bottom: calc(-1 * var(--mobile-bottom-offset));
    left: var(--mobile-bottom-padding);

    padding: 0 var(--mobile-bottom-padding);
  }

  * + .founder__image {
    margin-top: 42px;
  }

  .founder__image {
    position: relative;
  }

  .founder__image::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    content: '';

    background: linear-gradient(
      0deg,
      #000 6.35%,
      rgb(0 0 0 / 26%) 51.49%,
      rgb(0 0 0 / 0%) 70.64%
    );
  }
}
