.future-courses {
  overflow: hidden;

  color: var(--black);
}
* + .future-courses {
  margin-top: var(--section-gap);
}

.future-courses__slider {
  gap: 32px;
  list-style: none;
}
.future-courses__slider.keen-slider:not([data-keen-slider-disabled]) {
  overflow: visible;
}
.future-courses--loaded .future-courses__slider {
  gap: 0;
}
@media (--mobile) {
  .future-courses__slider {
    gap: 16px;
  }
}
