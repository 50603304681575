.clients,
:--layout-dark .clients,
:--group-dark .clients {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
  --infinite-shadow-rgb: var(--black-rgb);
}
:--layout-light .clients,
:--group-light .clients {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --infinite-shadow-rgb: var(--white-rgb);
}

.clients {
  --column-gap: 52px;
  --row-gap: 4px;
}
@media (--mobile) {
  .clients {
    --column-gap: 32px;
  }
}

.clients:first-child,
* + .clients {
  margin-top: var(--section-gap);
}

.hero--size-large + .clients {
  position: relative;
  z-index: var(--z-index-hero);

  margin-top: calc(-1 * var(--section-gap));
}
@media (--mobile) {
  .hero--size-large + .clients {
    margin-top: calc(-1 * var(--section-gap) + 32px);
  }
}

.clients__title {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.2;
  letter-spacing: 0.01em;
  color: rgba(var(--color-rgb), 0.6);
  text-align: center;
}

.clients__slider {
  position: relative;

  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  margin-right: calc(-1 * var(--page-right-padding));
  margin-left: calc(-1 * var(--page-left-padding));
  mask-image: linear-gradient(
    to right,
    transparent,
    black 20%,
    black 80%,
    transparent
  );
}
* + .clients__slider {
  margin-top: 32px;
}

.clients__slider__wrap {
  display: flex;
  flex: 0 0 auto;
  flex-wrap: nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  padding-left: var(--column-gap);
  column-gap: var(--column-gap);

  opacity: 0.8;
  animation: clients-mobile-wrap infinite calc(var(--count) * 3000ms) linear;
}
@keyframes clients-mobile-wrap {
  0% {
    transform: translateX(75%);
  }
  100% {
    transform: translateX(-25%);
  }
}

.clients__item {
  display: flex;
  align-items: center;
  justify-content: center;
}

.clients__item img {
  width: auto;
  height: 52px;
  object-fit: contain;
  user-select: none;
}
@media (--mobile) {
  .clients__item img {
    height: 52px;
  }
}
:--layout-dark .clients__item img,
:--group-dark .clients__item img {
  filter: brightness(100%) invert(1);
}
