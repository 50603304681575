.contact-form {
  --vertical-padding: 72px;

  position: relative;

  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);
}
.contact-form--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}
@media (--tablet) {
  .contact-form {
    --vertical-padding: 64px;
  }
}
@media (--mobile) {
  .contact-form {
    --vertical-padding: 40px;
  }
}

.contact-form__wrap {
  display: flex;
  flex-direction: column;
}
.contact-form__content {
  width: 100%;
  max-width: 55ch;
}
@media (--tablet) {
  .contact-form__content {
    max-width: none;
  }
}

.contact-form__background__right {
  position: absolute;
  top: calc(0px - var(--vertical-padding) - var(--header-height));
  right: 0%;

  width: fit-content;
  height: 90%;
  object-fit: contain;
  user-select: none;
}
@media (max-width: 1400px) {
  .contact-form__background__right {
    right: -10%;

    height: 85%;
  }
}
@media (--tablet) {
  .contact-form__background {
    display: none;
  }
}
.contact-form__title {
  font-family: var(--font-family);
  font-size: 48px;
  font-weight: 700;
  line-height: 130%;
  letter-spacing: 0.04em;
}
@media (--tablet) {
  .contact-form__title {
    font-size: 40px;
  }
}

@media (--mobile) {
  .contact-form__title {
    font-size: 32px;
  }
}

.contact-form__text {
  font-family: var(--font-family);
  font-size: 20px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.04em;
  color: rgba(var(--white-rgb), 0.8);
}

@media (--tablet) {
  .contact-form__text {
    font-size: 16px;
  }
}

@media (--mobile) {
  .contact-form__text br {
    display: none;
  }
}

* + .contact-form__text {
  margin-top: 40px;
}

@media (--tablet) {
  * + .contact-form__text {
    margin-top: 32px;
  }
}

@media (--mobile) {
  * + .contact-form__text {
    margin-top: 24px;
  }
}

.contact-form__bottom {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 88px;
}

@media (--tablet) {
  .contact-form__bottom {
    grid-template-columns: 1fr;
    gap: 40px;
  }
}

* + .contact-form__bottom {
  margin-top: 40px;
}

@media (--tablet) {
  * + .contact-form__bottom {
    margin-top: 28px;
  }
}

.contact-form__items {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
}
@media (--tablet) {
  .contact-form__items {
    gap: 24px;
    margin-top: 0;
  }
}

.contact-form__item {
}

.contact-form__item:first-of-type p {
  display: flex;
  gap: 24px;
}

.contact-form__item img {
  width: auto;
  max-width: 48px;
  height: 18px;
}

.contact-form__item__title {
  font-family: var(--font-family);
  font-size: 16px;
  font-weight: 700;
  line-height: 100%;
}

* + .contact-form__item__text {
  margin-top: 16px;
}

.contact-form__item__text a,
.contact-form__item__text a:visited {
  font-family: var(--font-family);
  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  color: var(--white);

  text-decoration: none;
}

.contact-form__item__text a:hover {
  text-decoration: underline;
}

.contact-form__item__linkedins {
}
* + .contact-form__item__linkedins {
  margin-top: 16px;
}

.contact-form__item__linkedins a {
  --vertical-padding: 12px;

  display: flex;
  align-items: center;
  gap: 8px;
  padding-top: var(--vertical-padding);
  padding-bottom: var(--vertical-padding);

  color: var(--white);
  text-decoration: none;
}
.contact-form__item__linkedins a:hover {
  text-decoration: underline;
}

.contact-form__item__linkedins a img {
  --size: 16px;

  width: var(--size);
  height: var(--size);
}
