.future-courses__item {
  --image-height: 190px;
  --padding: 32px;

  position: relative;

  display: flex;
  flex: 1 0 var(--page-max-width);
  align-items: stretch;
  gap: 24px;
  padding: var(--padding);

  border-radius: 16px;
  background: #f3f4f8;
}
@media (--page-max-width) {
  .future-courses__item {
    flex-basis: calc(100vw - var(--page-horizontal-padding) * 2);
  }
}
@media (--tablet) {
  .future-courses__item {
    --padding: 24px;

    gap: 16px;
  }
}
@media (--mobile) {
  .future-courses__item {
    --padding: 16px;

    flex-direction: column;
    flex-basis: 280px;
    gap: 16px;
  }
}

.future-courses__item__image {
  --width: 400px;

  display: block;
  flex: 0 0 var(--width);
  width: var(--width);
  height: auto;

  border-radius: 8px;
  object-fit: cover;
}
@media (--tablet) {
  .future-courses__item__image {
    --width: 300px;
  }
}
@media (--mobile) {
  .future-courses__item__image {
    flex-basis: auto;
    flex-grow: 0;
    width: 100%;
    height: var(--image-height);
  }
}

.future-courses__item__content {
  flex: 1;
}

.future-courses__item__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
}
@media (--mobile) {
  .future-courses__item__header {
    flex-direction: column-reverse;
    align-items: flex-start;
    gap: 8px;
  }
}

.future-courses__item__header__emojis {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  gap: 8px;
}
.future-courses__item__header__emojis li {
  --size: 40px;

  display: flex;
  align-items: center;
  justify-content: center;
  width: var(--size);
  height: var(--size);

  border-radius: 50%;
  background-color: rgba(var(--black-rgb), 0.05);
}
.future-courses__item__header__emojis img {
  --size: 20px;

  width: var(--size);
  height: var(--size);
}
@media (--mobile) {
  .future-courses__item__header__emojis {
    --offset: 8px;

    position: absolute;
    top: calc(var(--padding) + var(--image-height) - var(--offset));
    right: calc(var(--padding) + var(--offset));

    transform: translateY(-100%);
  }
  .future-courses__item__header__emojis li {
    --size: 32px;

    background-color: rgba(var(--black-rgb), 0.1);
    backdrop-filter: blur(12px);
  }
  .future-courses__item__header__emojis img {
    --size: 16px;
  }
}

.future-courses__item__title {
  font-size: 32px;
  font-weight: 600;
  line-height: 1.3;
  letter-spacing: 0.01em;
}
* + .future-courses__item__title {
  margin-top: 12px;
}
@media (--tablet) {
  .future-courses__item__title {
    font-size: 24px;
  }
}
@media (--mobile) {
  .future-courses__item__title {
    font-size: 18px;
  }
  * + .future-courses__item__title {
    margin-top: 8px;
  }
}

.future-courses__item__tags {
  display: inline-flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
}
* + .future-courses__item__tags {
  margin-top: 16px;
}

.future-courses__item__tag {
  padding: 4px 16px 5px 16px;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: rgba(var(--black-rgb), 0.7);

  border-radius: 15px;
  background-color: rgba(var(--black-rgb), 0.05);
}
.future-courses__item__tag--style-primary {
  color: var(--primary-500);

  border-radius: 32px;
  background: rgba(var(--primary-500-rgb), 0.1);
}
@media (--tablet) {
  .future-courses__item__tag--style-primary {
    font-size: 15px;
  }
}
@media (--mobile) {
  .future-courses__item__tag {
    padding: 3px 12px 4px 12px;

    border-radius: 13px;
    font-size: 14px;
    overflow-wrap: break-word;
  }
}

.future-courses__item__description {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  color: rgba(var(--dark-rgb), 0.6);
}
* + .future-courses__item__description {
  margin-top: 24px;
}
.future-courses__item__description * + p {
  margin-top: 1em;
}
@media (--tablet) {
  .future-courses__item__description {
    font-size: 16px;
  }
}
@media (--mobile) {
  .future-courses__item__description {
    font-size: 15px;
  }
  * + .future-courses__item__description {
    margin-top: 16px;
  }
}
