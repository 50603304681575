.calendar,
:--layout-dark .calendar,
:--group-dark .calendar {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .calendar,
:--group-light .calendar {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

.calendar:first-child,
* + .calendar {
  margin-top: var(--section-gap);
}

.calendar {
  color: var(--color);
}

.calendar__content {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
