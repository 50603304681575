.form__form {
  --gap: 8px;

  width: 100%;
}
* + .form__form {
  margin-top: 32px;
}
@media (--tablet) {
  * + .form__form {
    margin-top: 16px;
  }
}

.form__line {
  display: flex;
  gap: var(--gap);
}
* + .form__line {
  margin-top: var(--gap);
}

.form__line > .field {
  flex: 1;
}

* + .form__cta {
  margin-top: 16px;
}

.form__success {
  max-width: 35ch;

  font-size: 18px;
  line-height: 1.6;
}
* + .form__success {
  margin-top: 24px;
}
@media (--mobile) {
  .form__success {
    font-size: 16px;
  }
}
