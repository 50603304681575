.field {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
  --border-radius: 4px;
}
.field--theme-dark {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
  --border-radius: 8px;
}

.field__border {
  position: relative;
}
.field--theme-dark .field__border::before {
  position: absolute;

  content: '';
  padding: 1px;

  border-radius: var(--border-radius);
  background: linear-gradient(
    84deg,
    rgba(var(--white-rgb), 0) -3.89%,
    rgba(var(--white-rgb), 0) -3.89%,
    rgba(var(--white-rgb), 0.26) 64.19%,
    rgba(var(--white-rgb), 0) 132.27%
  );
  pointer-events: none;
  inset: 0;
  mask:
    conic-gradient(#000 0 0) content-box exclude,
    conic-gradient(#000 0 0);
}

.field__input {
  display: block;
  width: 100%;
  min-width: 100px;
  padding: 16px;

  color: var(--color);

  border: 1px solid rgba(var(--black-rgb), 0.05);

  border-radius: var(--border-radius);
  background: #f5f5f9;
  cursor: text;
}
.field--as-textarea .field__input {
  min-height: 120px;
  resize: vertical;
}
.field--theme-dark .field__input {
  border: none;
  background: none;
  background-image: linear-gradient(
    213deg,
    rgba(var(--white-rgb), 0.1) 4.4%,
    rgba(var(--white-rgb), 0.2) 87.78%
  );
}

.field__input::placeholder {
  position: relative;
  top: -1px;

  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  letter-spacing: -0.03em;
  color: rgba(var(--color-rgb), 0.8);
}

.field__error {
  display: block;
  padding-bottom: 6px;

  font-size: 14px;
  font-weight: 400;
  line-height: 100%;
  letter-spacing: -0.03em;
  color: var(--primary-500);
}
* + .field__error {
  margin-top: 4px;
}
