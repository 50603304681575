.cta-item {
  margin-top: var(--section-gap);
  text-align: center;
}
.heading + .cta-item,
.line-formations + .cta-item {
  margin-top: 32px;
}
@media (--mobile) {
  .cta-item {
    margin-top: 32px;
  }
}

.cta-item__wrap {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 16px;
}
@media (--mobile) {
  .cta-item__wrap {
    gap: 12px;
  }
}
