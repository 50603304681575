.countdown-hero {
  --form-top-offset: 92px;

  overflow: hidden;
}

@media (--mobile) {
  .countdown-hero {
    --form-top-offset: 52px;
  }
}

.countdown-hero__content {
  position: relative;

  padding-top: calc(var(--header-height) + 50px);
  padding-bottom: calc(var(--form-top-offset) + 50px);
}
@media (--tablet) {
  .countdown-hero__content {
    padding-top: calc(var(--header-height) + 24px);
    padding-bottom: calc(var(--form-top-offset) + 24px);
  }
}
@media (--mobile) {
  .countdown-hero__content {
    padding-top: 64px;
    padding-bottom: calc(var(--form-top-offset) + 220px);
  }
}

.countdown-hero__content__line {
  position: absolute;
  bottom: 0;
  left: 50%;

  content: '';
  width: 90%;
  height: 1px;

  background-image: linear-gradient(
    to right,
    rgba(var(--white-rgb), 0),
    rgba(var(--white-rgb), 0.3),
    rgba(var(--white-rgb), 0)
  );
  transform: translateX(-50%);
}

.countdown-hero__title {
  position: relative;
  z-index: 1;

  max-width: 32ch;
  margin-right: auto;

  font-size: 40px;
  font-weight: 700;
  line-height: 1.3;
  color: var(--white);

  text-align: left;
}
.countdown-hero__title strong {
  color: var(--primary-500);
}
@media (--tablet) {
  .countdown-hero__title {
    font-size: 28px;
  }
}
@media (--mobile) {
  .countdown-hero__title {
    max-width: 28ch;
    margin-right: auto;
    margin-left: auto;

    font-size: 24px;
    line-height: 1.5;
    text-align: center;
  }
}

@media (--mobile) {
  .countdown-hero__title br {
    display: none;
  }
}

.countdown-hero__description {
  position: relative;
  z-index: 1;

  max-width: 42ch;

  font-size: 24px;

  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.24;
  color: rgba(var(--white-rgb), 0.8);
  text-align: left;
}
* + .countdown-hero__description {
  margin-top: 40px;
}
@media (--tablet) {
  .countdown-hero__description {
    font-size: 17px;
  }
  * + .countdown-hero__description {
    margin-top: 32px;
  }
}
@media (--mobile) {
  .countdown-hero__description {
    margin-right: auto;
    margin-left: auto;

    font-size: 15px;
    text-align: center;
  }
  * + .countdown-hero__description {
    margin-top: 16px;
  }
}
