.company-bubble-list,
:--layout-dark .company-bubble-list,
:--group-dark .company-bubble-list {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .company-bubble-list,
:--group-light .company-bubble-list {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

* + .company-bubble-list {
  margin-top: var(--section-gap);
}

.company-bubble-list {
  margin-top: var(--section-gap);
}

.company-bubble-list__wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 24px;
}

@media (--mobile) {
  .company-bubble-list__wrap {
    gap: 12px;
  }
}

.company-bubble-list__pictures {
  display: flex;
  justify-content: center;
}

.company-bubble-list__pictures__image {
  position: relative;

  width: 80px;
  height: 80px;
  margin-right: -20px;

  /* Ajustez cette valeur selon votre préférence */
}

.company-bubble-list__pictures__image:last-child {
  margin-right: 0;
}

.company-bubble-list__pictures__image__img {
  position: absolute;

  width: 100%;
  height: 100%;

  border: 3px solid var(--white);

  border-radius: 50%;
  object-fit: cover;
}

.company-bubble-list__pictures__company {
  position: absolute;
  bottom: 0;
  left: 0;

  width: 45%;
  height: 45%;
}

.company-bubble-list__pictures__company img {
  position: absolute;

  width: 100%;
  height: 100%;

  object-fit: cover;
}

.company-bubble-list__text {
  font-size: 16px;

  font-weight: 600;
  line-height: 100%; /* 16px */
  letter-spacing: 0.06em;
  color: rgba(var(--color-rgb), 1);

  text-align: center;
}

@media (--mobile) {
  .company-bubble-list__text {
    font-size: 14px;
  }
}

@media (--mobile) {
  * + .company-bubble-list {
    margin-top: 64px;
  }

  .company-bubble-list__description h2 {
    font-size: 24px;
    line-height: 130%; /* 41.6px */
    letter-spacing: 0.08em;
  }

  .company-bubble-list__description p {
    margin-top: 24px;

    font-size: 16px;
    line-height: 140%; /* 22.4px */
    letter-spacing: 0.01em;
  }

  .company-bubble-list__pictures__image {
    width: 45px;
    height: 45px;
    margin-right: -20px; /* Ajustez cette valeur selon votre préférence */
  }

  .company-bubble-list__description__circles {
    margin-top: 24px;
  }

  .company-bubble-list__pictures {
    display: flex;
    justify-content: center;
  }
}
