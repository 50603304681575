.comment-cards {
  --gap: 24px;
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}
@media (--mobile) {
  .comment-cards {
    --gap: 16px;
  }
}

.comment-cards:first-child,
* + .comment-cards {
  margin-top: var(--section-gap);
}

.comment-cards__rating {
  display: flex;
  flex-wrap: wrap;
  align-items: baseline;
  gap: 20px;
}

.comment-cards__text-rating {
  font-size: 42px;
  font-weight: 600;
  line-height: 1;
  color: var(--color);
}
@media (--mobile) {
  .comment-cards__text-rating {
    font-size: 32px;
  }
}

.comment-cards__rating__icon {
  width: auto;
  height: 30px;
  object-fit: contain;
}
@media (--mobile) {
  .comment-cards__rating__icon {
    height: 24px;
  }
}

.comment-cards__see-all {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-left: auto;

  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  letter-spacing: 0.05em;
}
.comment-cards__see-all img {
  --size: 24px;

  position: relative;
  top: 3px;

  width: var(--size);
  height: var(--size);
}
@media (--mobile) {
  .comment-cards__see-all {
    gap: 8px;

    font-size: 14px;
    letter-spacing: 0.025em;
  }
  .comment-cards__see-all img {
    --size: 18px;
  }
}

.comment-cards__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: var(--gap);

  color: var(--color);
}
* + .comment-cards__cards {
  margin-top: 40px;
}
@media (--mobile) {
  .comment-cards__cards {
    grid-template-columns: 1fr;
  }
  * + .comment-cards__cards {
    margin-top: 24px;
  }
}

.comment-cards__rest {
  --js-scroll-offset-top: var(--header-height);
}
* + .comment-cards__rest {
  padding-top: var(--gap);
}
