.calendar__item {
  --vertical-padding: 16px;
  --gap: 32px;

  display: grid;
  align-items: center;
  grid-template-columns: 90px 1fr 200px;
  gap: var(--gap);
  padding-top: var(--vertical-padding);
  padding-right: 24px;
  padding-bottom: var(--vertical-padding);
  padding-left: 16px;

  border: 1px solid #cbcbcb;
  border-radius: 16px;
}
@media (--tablet) {
  .calendar__item {
    --gap: 16px;

    grid-template-columns: 64px 1fr 200px;
  }
}
@media (--mobile) {
  .calendar__item {
    grid-template-columns: 1fr;
  }
}

.calendar__item__date {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  padding-right: 12px;

  font-size: 32px;
  font-weight: 800;
  line-height: 1;

  border-right: 1px solid rgba(var(--color-rgb), 0.4);
  text-align: center;
}
.calendar__item__date b {
  font-size: 20px;
  font-weight: 700;
  color: var(--primary-500);
}
@media (--tablet) {
  .calendar__item__date {
    padding-right: 0;

    font-size: 24px;

    border-right: none;
  }
  .calendar__item__date b {
    font-size: 16px;
  }
}

.calendar__item__wrap {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: var(--gap);
}
@media (--tablet) {
  .calendar__item__wrap {
    grid-template-columns: 1fr;
    gap: 8px;
  }
}

.calendar__item__title {
  font-size: 20px;
  font-weight: 700;
  line-height: 140%;
}

.calendar__item__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 150%;
}
* + .calendar__item__description {
  margin-top: 4px;
}

.calendar__item__details {
  font-size: 16px;
  font-weight: 400;
  line-height: 150%;
  color: rgba(var(--color-rgb), 0.6);
}

.calendar__item__avatars {
  display: flex;
}
* + .calendar__item__avatars {
  margin-top: 12px;
}

.calendar__item__avatars img {
  --size: 44px;

  width: var(--size);
  height: var(--size);

  border: 2px solid #d5d5d5;
  border-radius: 999px;
  object-fit: cover;
}
.calendar__item__avatars * + img {
  margin-left: -16px;
}

.calendar__item__cta {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 8px;
}
@media (--mobile) {
  .calendar__item__cta {
    align-items: stretch;
    text-align: center;
  }
}
