.countdown-hero__content__background__gradient {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  width: 100%;
  max-width: 100%;

  pointer-events: none;
  user-select: none;

  animation-name: countdown-hero-content-background;
  animation-duration: 1500ms;
  animation-fill-mode: forwards;
}
@media (--mobile) {
  .countdown-hero__content__background {
    width: 160%;
  }
}

@keyframes countdown-hero-content-background {
  from {
    opacity: 0.5;
  }
  to {
    opacity: 1;
  }
}

.countdown-hero__content__background__image {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: flex;
  justify-content: flex-end;
  overflow: hidden;
  width: 100%;
  height: 100%;

  pointer-events: none;
  user-select: none;
}
@media (max-width: 1400px) {
  .countdown-hero__content__background__image {
    width: 110%;
  }
}
@media (--page-max-width) {
  .countdown-hero__content__background__image {
    width: 120%;
  }
}
@media (--mobile) {
  .countdown-hero__content__background__image {
    top: 45%;

    width: 100%;
    height: auto;
  }
}

.countdown-hero__content__background__image img {
  width: auto;
  height: 100%;
  object-fit: contain;
  object-position: center right;
}
@media (--mobile) {
  .countdown-hero__content__background__image img {
    width: 100%;
    height: auto;
    object-position: bottom center;
  }
}

/* @media (max-width: 1600px) {
  .countdown-hero__content__background__image::after {
    position: absolute;
    top: 0;
    left: 0;

    content: '';
    display: block;
    width: 100%;
    height: 100%;

    background-image: linear-gradient(
      to right,
      rgba(0, 0, 0, 0.5) 60%,
      rgba(0, 0, 0, 0) 90%
    );
  }
} */
@media (--mobile) {
  .countdown-hero__content__background__image::after {
    display: none;
  }
}

.countdown-hero__content__background__gradient::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  background-image: url('/illustrations/countdown-hero-noise.png');
  background-repeat: repeat;
  background-size: 231px 231px;
  background-position: center center;
  opacity: 0.4;
  mix-blend-mode: overlay;
}

.countdown-hero__content__background__gradient::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  content: '';

  background-image: radial-gradient(
    50% 85% at 50% 100%,
    rgba(227, 104, 100, 30%) 0%,
    rgba(227, 104, 100, 0%) 100%
  );
  transform-origin: bottom center;

  animation-name: countdown-hero-content-background-scale;
  animation-duration: 4000ms;
  animation-fill-mode: forwards;
}
@media (--mobile) {
  .countdown-hero__content__background__gradient::after {
    top: 40%;

    background-image: radial-gradient(
      60% 90% at 50% 100%,
      rgba(227, 104, 100, 0.3) 0%,
      rgba(227, 104, 100, 0) 100%
    );
  }
}

@keyframes countdown-hero-content-background-scale {
  from {
    transform: scale(0.5);
  }
  to {
    transform: scale(1);
  }
}

.countdown-hero__content__background__circles {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;

  width: 100%;
  height: auto;
}
