.banner,
:--layout-dark .banner,
:--group-dark .banner {
  --color: var(--white);
  --color-rgb: var(--white-rgb);
}
:--layout-light .banner,
:--group-light .banner {
  --color: var(--black);
  --color-rgb: var(--black-rgb);
}

* + .banner {
  margin-top: var(--section-gap);
}

.banner {
  margin-top: var(--section-gap);
}

.banner__content {
  position: relative;
}

@media (--mobile) {
  .banner__content {
    display: none;
  }
}

.banner__content-mobile {
  display: none;
}

@media (--mobile) {
  .banner__content-mobile {
    position: relative;

    display: block;
  }
}

.banner__content__image-desktop {
  display: flex;
  width: 100%;
  height: auto;
  margin-right: auto;
  margin-left: auto;
  object-fit: contain;
}

@media (--tablet) {
  .banner__content__image-desktop {
    display: none;
  }
}

.banner__content__image-tablet {
  display: none;
}

@media (--tablet) {
  .banner__content__image-tablet {
    display: flex;
    width: 90%;
    height: auto;
    margin-right: auto;
    margin-left: auto;
    object-fit: contain;
  }
}

@media (--mobile) {
  .banner__content__image-tablet {
    display: none;
  }
}

.banner__content__image-mobile {
  display: none;
}

@media (--mobile) {
  .banner__content__image-mobile {
    display: flex;
    width: 100%;
    height: 700px;

    object-fit: cover;
  }
}

.banner__content__text-container {
  position: absolute;
  top: 38px;
  left: 38px;

  width: 44%;
}

@media (--tablet) {
  .banner__content__text-container {
    position: absolute;
    top: 38px;
    left: 140px;

    width: 60%;
  }
}

@media (--mobile) {
  .banner__content__text-container {
    position: absolute;
    top: 24px;
    left: 6%;

    width: 90%;
  }
}

.banner__content__text {
  font-size: 24px;
  font-weight: 500;
  line-height: 130%; /* 33.8px */
  letter-spacing: -1.04px;
}
@media (--mobile) {
  .banner__content__text {
    font-size: 20px;
  }
}

.banner__content__text strong {
  font-size: 24px;
  font-weight: 500;
  line-height: 130%; /* 33.8px */
  letter-spacing: -1.04px;
  color: var(--primary-500);
}

@media (--mobile) {
  .banner__content__text strong {
    font-size: 20px;
  }
}

.banner__content__subtext {
  margin-top: 16px;

  font-size: 18px;
  font-weight: 400;
  line-height: 130%;
  letter-spacing: -0.8px;
}

@media (--mobile) {
  .banner__content__subtext {
    font-size: 16px;
  }
}
