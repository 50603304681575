.countdown-form__title {
  font-size: 20px;
  font-weight: 400;
  line-height: 140%;

  letter-spacing: 0.01em;
  color: #fff;
  text-align: center;
}

@media (--mobile) {
  .countdown-form__title {
    max-width: 25ch;
    margin-right: auto;
    margin-left: auto;

    font-size: 17px;
  }
}

.countdown-form__title strong {
  font-weight: 600;
}

.countdown-form__subtitle {
  font-size: 16px;
  font-weight: 300;
  line-height: 140%;
  letter-spacing: 0.01em;
  color: rgba(var(--white-rgb), 0.7);
  text-align: center;
}
@media (--mobile) {
  .countdown-form__subtitle,
  .countdown-form__subtitle label {
    font-size: 15px;
  }
}

* + .countdown-form__subtitle {
  margin-top: 12px;
}
@media (--mobile) {
  * + .countdown-form__subtitle {
    margin-top: 16px;
  }
}
