* + .companies {
  margin-top: 82px;
}

.companies {
  --vertical-padding: 100px;
  padding-bottom: 104px;
}

@media (--mobile) {
  .companies {
    margin-top: 64px;
  }
}

.companies--first {
  padding-top: calc(var(--vertical-padding) + var(--header-height));
}

.companies__logos {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 105px;
}

.companies__logo {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
}

.companies__logo img {
  max-width: 228px;
  max-height: 93px;
}

@media (--mobile) {
  .companies__logos {
    gap: 50px 75px;
  }

  .companies__logo {
    max-width: 115px;
  }
}
