.sup-title {
  font-size: 16px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 0.01em;
  color: var(--primary-500);
  text-transform: uppercase;
}
@media (--mobile) {
  .sup-title {
    font-size: 15px;
  }
}
